<template>
  <div>
    <p class="text-2xl">
      Dashboard
    </p>

    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <validation-observer
              ref="formAdminDashboard"
              autocomplete="off"
              tag="form"
              @keyup.enter="getValues"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  sm="6"
                >
                  <date
                    v-model="startDate"
                    :type="'date'"
                    outlined
                    hide-details
                    label="Data Envio (Início)"
                    dense
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                  sm="6"
                >
                  <date
                    v-model="endDate"
                    :type="'date'"
                    outlined
                    dense
                    hide-details
                    label="Data Envio (Fim)"
                  />
                </v-col>
              </v-row>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="6"
        md="6"
      >
        <v-card outlined>
          <v-card-title>
            Serviços Utilizados
          </v-card-title>

          <v-card-text>
            <v-card outlined>
              <vue-apex-charts
                class="pa-5"
                :options="chartOptions"
                :series="chartData"
                height="400"
              />
            </v-card>

            <v-simple-table
              dense
              outlined
              class="mt-5"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>
                      Serviço
                    </th>
                    <th class="text-right">
                      Qtd. Cadastrada
                    </th>
                    <th class="text-right">
                      Qtd. Faturada
                    </th>
                    <th class="text-right">
                      Valor (R$)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="service in services"
                    :key="service.id"
                  >
                    <td>{{ service.name }}</td>
                    <td class="text-right">
                      {{ $money(service.quantity, 0) }}
                    </td>
                    <td class="text-right">
                      {{ $money(service.validQuantity, 0) }}
                    </td>
                    <td class="text-right">
                      {{ $money(service.value, 2) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="6"
        md="6"
      >
        <v-card
          outlined
        >
          <v-card-text>
            <v-row>
              <v-col
                cols="6"
                md="4"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="info"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiAccount }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    Usuários Ativos
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ totalActiveUsers }}
                  </h3>
                </div>
              </v-col>

              <v-col
                cols="6"
                md="4"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="info"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiAccountPlus }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    Novos Usuários
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    {{ totalNewUsers }}
                  </h3>
                </div>
              </v-col>

              <v-col
                cols="6"
                md="4"
                class="d-flex align-center"
              >
                <v-avatar
                  size="44"
                  color="success"
                  rounded
                  class="elevation-1"
                >
                  <v-icon
                    dark
                    color="white"
                    size="30"
                  >
                    {{ icons.mdiCashRegister }}
                  </v-icon>
                </v-avatar>
                <div class="ms-3">
                  <p class="text-xs mb-0">
                    Total Recargas
                  </p>
                  <h3 class="text-xl font-weight-semibold">
                    R$ {{ $money(totalOrders, 2) }}
                  </h3>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                Recargas por Usuário
                <v-divider class="mt-3" />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-simple-table
                  height="500"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          Posição
                        </th>
                        <th>
                          Cód. Usuário
                        </th>
                        <th>
                          Usuário
                        </th>
                        <th class="text-right">
                          Valor (R$)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(user,key) in userOrders"
                        :key="key"
                      >
                        <td class="text-center">
                          <v-avatar
                            size="35"
                            :class="`${key < 1 ? 'success' : 'info'} white--text font-weight-medium me-3`"
                          >
                            <span class="text-base">{{ key + 1 }}</span>
                          </v-avatar>
                        </td>
                        <td>{{ user.id }}</td>
                        <td>{{ user.name }}</td>
                        <td class="text-right">
                          {{ $money(user.total, 2) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import {
  mdiAccount,
  mdiAccountPlus,
  mdiCashRegister,
  mdiClose,
} from '@mdi/js'

export default {
  components: {
    VueApexCharts,
  },
  data() {
    const startDate = this.$day().startOf('month').format('DD/MM/YYYY')
    const endDate = this.$day().format('DD/MM/YYYY')

    const chartOptions = {
      chart: {
        type: 'bar',
        toolbar: { show: false },
        offsetX: -15,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          borderRadius: 5,
          startingShape: 'rounded',
          endingShape: 'rounded',
          barHeight: '70%',
        },
      },
      dataLabels: {
        enabled: true,
        formatter: value => `${this.$money(value, 2)}`,
        style: {
          fontSize: '14px',
        },
      },
      legend: { show: false },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          style: { fontSize: '12px' },
          formatter: value => `R$ ${this.$money(value, 2)}`,
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
        labels: {
          offsetY: 3,
          style: { fontSize: '12px' },
        },
      },
      stroke: { width: [1, 2] },
      grid: {
        strokeDashArray: 12,
        padding: { right: 0 },
      },
    }

    const chartData = [
      {
        data: [],
      },
    ]

    return {
      startDate,
      endDate,

      icons: {
        mdiAccount,
        mdiAccountPlus,
        mdiCashRegister,
        mdiClose,
      },

      services: ref([]),
      userOrders: ref([]),
      totalOrders: ref(0),
      totalActiveUsers: ref(0),
      totalNewUsers: ref(0),

      chartOptions,
      chartData,
    }
  },

  async created() {
    await this.getValues()
  },

  methods: {
    ...mapActions('adminDashboard', [
      'getTotalCampaigns',
      'getTotalOrders',
      'getActiveUsers',
      'getNewUsers',
      'getUserTotalOrders',
    ]),

    async getValues() {
      console.log('ok')
      const params = {
        startDate: this.$day(this.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        endDate: this.$day(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      }

      this.services = await this.getTotalCampaigns(params)
      this.userOrders = await this.getUserTotalOrders(params)

      const orders = await this.getTotalOrders(params)
      this.totalOrders = orders[0].total

      const activeUsers = await this.getActiveUsers()
      this.totalActiveUsers = activeUsers[0].total

      const newUsers = await this.getNewUsers(params)
      this.totalNewUsers = newUsers[0].total

      const categories = this.services.map(service => service.name)

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories,
          },
        },
      }

      this.chartData = [
        {
          data: this.services.map(service => service.value),
        },
      ]
    },
  },
}
</script>
